/* workout.css - Mobile First */

.timer-container {
    display: none;
    flex-grow: 1;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 1em;
    overflow: hidden;
    min-height: 50vh;
}

/* Show exercise and countdown in workout mode */
#exercise,
#countdown {
    display: block;
    font-size: 6vmin;
    text-align: center;
    color: #F5F5DC;
}

/* Up-next text */
#up-next-text {
    display: none;
    font-size: 6vmin;
    color: #F5F5DC;
    text-align: center;
    margin-top: 1em;
    position: absolute;
    top: calc(35% + 30vmin);
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 4;
}

/* Circle container */
.circle-container {
    display: flex;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50vmin;
    height: 50vmin;
    border-radius: 50%;
    cursor: pointer;
    background-color: #00C2C7;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.1);
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 3;
}

.circle-content {
    font-size: 6vw;
    color: #F5F5DC;
    text-align: center;
}

/* Overlay circle */
.circle-overlay {
    display: block;
    position: absolute;
    top: 35%;
    left: 50%;
    width: 55vmin;
    height: 55vmin;
    border-radius: 50%;
    background: conic-gradient(#39CCCC 0%, rgba(57, 204, 204, 0) 0%);
    transform: translate(-50%, -50%);
    z-index: 2;
    opacity: 1;
    transition: background 0.3s;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}

/* Progress circle */
.progress-circle {
    display: flex;
    position: absolute;
    top: 35%;
    left: 50%;
    width: 55vmin;
    height: 55vmin;
    border-radius: 50%;
    align-items: center;
    transform: translate(-50%, -50%);
    justify-content: center;
    background-color: grey;
    z-index: 1;
}

/* Restart Button */
#restart-button {
    border: none;
    background: none;
    font-size: 6vmin;
    color: #F5F5DC;
    cursor: pointer;
    text-align: center;
    margin-top: 2em;
    top: calc(50% + 25vmin);
    position: absolute;
    font-weight: bold;
}

/* Add hover effect */
#restart-button:hover {
    color: #00C2C7;
    transform: scale(1.1);
    transition: color 0.3s, transform 0.3s;
}

/* Media Queries */

/* Tablet (min-width: 481px) */
@media (min-width: 481px) {
    .circle-container {
        width: 40vmin;
        height: 40vmin;
    }

    .circle-overlay,
    .progress-circle {
        width: 45vmin;
        height: 45vmin;
    }

    #exercise,
    #countdown,
    #up-next-text {
        font-size: 4vmin;
    }

    #up-next-text {
        margin-top: 0em;
    }

    #restart-button {
        margin-top: 0.5em;
    }
}