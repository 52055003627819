.timer-container {
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-around;
  align-items: center;
  min-height: 50vh;
  padding: 1em;
  display: none;
  position: relative;
  overflow: hidden;
}

#exercise, #countdown {
  text-align: center;
  color: beige;
  font-size: 6vmin;
  display: block;
}

#up-next-text {
  color: beige;
  text-align: center;
  z-index: 4;
  margin-top: 1em;
  font-size: 6vmin;
  display: none;
  position: absolute;
  top: calc(35% + 30vmin);
  left: 50%;
  transform: translate(-50%);
}

.circle-container {
  cursor: pointer;
  z-index: 3;
  background-color: #00c2c7;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50vmin;
  height: 50vmin;
  display: flex;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 1em #0000001a;
}

.circle-content {
  color: beige;
  text-align: center;
  font-size: 6vw;
}

.circle-overlay {
  z-index: 2;
  opacity: 1;
  background: conic-gradient(#39cccc 0%, #39cccc00 0%);
  border-radius: 50%;
  width: 55vmin;
  height: 55vmin;
  transition: background .3s;
  display: block;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px #fffc;
}

.progress-circle {
  z-index: 1;
  background-color: gray;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 55vmin;
  height: 55vmin;
  display: flex;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#restart-button {
  color: beige;
  cursor: pointer;
  text-align: center;
  background: none;
  border: none;
  margin-top: 2em;
  font-size: 6vmin;
  font-weight: bold;
  position: absolute;
  top: calc(50% + 25vmin);
}

#restart-button:hover {
  color: #00c2c7;
  transition: color .3s, transform .3s;
  transform: scale(1.1);
}

@media (width >= 481px) {
  .circle-container {
    width: 40vmin;
    height: 40vmin;
  }

  .circle-overlay, .progress-circle {
    width: 45vmin;
    height: 45vmin;
  }

  #exercise, #countdown, #up-next-text {
    font-size: 4vmin;
  }

  #up-next-text {
    margin-top: 0;
  }

  #restart-button {
    margin-top: .5em;
  }
}
/*# sourceMappingURL=index.bb801e51.css.map */
